@import "../../../../../styles/variables.scss";

.accordionGrid {
  display: grid;
  gap: 20px;
  grid-template-columns: 40px 1fr 4fr 3fr 3fr 40px;
  margin-top: 36px;
  margin-bottom: 36px;
}

.accordionHeader {
  display: grid;
  gap: 20px;
  grid-template-columns: 40px 1fr 4fr 3fr 3fr 40px;
}

.accordionItem {
  display: flex;
  justify-content: center;
}

.questionNumber {
  width: 29px;
  height: 44px;
  border-radius: 4px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.questionNumberGood {
  border: 2px solid $color-green;
}
.questionNumberBad {
  border: 2px solid $color-red-sec;
}
.questionContent {
  height: 120px;
  width: 175px;
  object-fit: cover;
  border-radius: 4px;
}

.expandButton {
  width: 40px;
  height: 40px;
  background-color: $color-primary;
  border-radius: 6px;
  border: 2px solid $color-secondary;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.question_bank_option {
  display: grid;
  grid-template-columns: 20px 1fr;
  column-gap: 10px;
  align-items: center;
  margin-top: 12px;
  padding: 4px;

  & .optionNumber {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
  }
}

.optionCorrect {
  & .optionNumber {
    background-color: theme-color(mainGreen);
    color: theme-color(background-color);
  }
  & .optionName {
    color: theme-color(mainGreen);
  }
}

.dialogContent {
  display: flex;
  flex-direction: column;
}

.headerText {
  font-size: 16px;
  font-weight: 400;
  color: $color-white-secondary;
  margin-top: 8px;
}

.selectContainer {
  margin-top: 36px;
}

.saveButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 36px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
}

.selectBox {
  display: grid;
  justify-content: flex-end;
  align-items: center;
  grid-template-columns: 5fr 2fr 2fr;
  gap: 24px;
}
.selectBox2 {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr minmax(300px, 2fr) 1fr 1fr;
  gap: 12px;
}
.cardContainer {
  padding: 24px;
}
.headContainer {
  width: 175px;
}
.accordion {
  width: 18px;
}
.radio {
  display: flex;
  margin-top: 6px;
}
.remark {
  word-break: break-all;
}

.questionCount {
  padding: 10px;
  font-family: "Inter-Medium", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  background-color: $color-secondary;
  border-radius: 40px;
  vertical-align: middle;
}
