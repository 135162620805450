.toggleGroup {
  display: flex;
  justify-content: flex-end;

  .toggleItem {
    display: flex;
    margin-right: 22px;

    &:last-child {
      margin-right: 18px;
    }

    .toggleLabel {
      margin-left: 8px;
    }
  }
}

.tableAvatar {
  display: flex;
  width: 100%;
  align-items: center;

  &__img {
    margin-right: 24px;
    width: 48px;
    height: 48px;

    & > img {
      border-radius: 50%;
      object-fit: cover;

      height: 100%;
    }
  }
}

.rank {
  width: 29.05px;
  height: 44px;
  border-radius: 6px;
  border: 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rankCell {
  position: sticky !important;
  left: 0;
  z-index: 20 !important;
  width: 60px;
}

.nameCell {
  position: sticky !important;
  z-index: 20 !important;
  left: 57px;
  max-width: 250px;
}

.stickyRight {
  position: sticky !important;
  right: -1px;
  z-index: 20 !important;
  min-width: 110px;
}

.headerText {
  opacity: 0.6;
}

.quizCell {
  min-width: 180px;
}
