@import "../../styles/variables.scss";

.likeDislikeContainer {
  display: flex;
  padding: 12px 0;
  border-bottom: 2px solid $color-secondary-main;
}

.noDataContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}

.optionBox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  margin-right: 22px;
  cursor: pointer;
  border-bottom: 4px solid $color-background-default;
}

.icon {
  margin-right: 8px;
  padding-bottom: 7px;
}

.selectedOption {
  border-bottom: 4px solid $color-secondary;
}

.divider {
  margin-top: 8px;
  height: 39.459px;
  margin-right: 20px;
  border-left: 2px solid $color-secondary-main;
}

.countsContainer {
  margin-top: 10px;
  max-height: 405px;
  overflow-y: auto;
}
.count {
  padding-right: 3px;
  padding-bottom: 3px;
}

.countItem {
  display: flex;
  align-items: center;
  gap: 21px;
  padding: 12px 0px;
}

.avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.rank {
  width: 29.05px;
  height: 44px;
  border-radius: 6px;
  border: 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
}
