@import "../../../styles/variables.scss";

.userDataContainer {
  padding: 12px;
  width: 352px;
  box-sizing: border-box;
  position: relative;
}

.graphContainer {
  padding: 12px;
  width: 388px;
  box-sizing: border-box;
  position: relative;
  height: 540px !important;
}

.imgContainer {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  z-index: 99999999;
  top: -60px;

  & img {
    height: 120px;
    width: 120px;
    z-index: 999999;
    border-radius: 50%;
    object-fit: cover;
  }
}

.rank {
  padding: 6px;
  margin-top: 10px !important;
  border: 2px solid #e67c1b;
  border-radius: 6px;
  color: #ffffff !important;
  opacity: 1 !important;
}

.dataContainer {
  margin-top: 24px;
  width: 100%;

  & .dataItem {
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:nth-child(odd) {
      background-color: #2e2926;
      border-radius: 6px;
    }
  }
}

.key {
  opacity: 0.6;
}

.arrowBtn {
  opacity: 0.5;
  font-size: 16px !important;
  cursor: pointer;
  font-weight: 900;

  &:hover {
    opacity: 1;
  }
}

.top {
  background-color: transparent;
  width: 94%;
  top: 20px;
  position: absolute;
  height: 10%;
  display: flex;
  align-items: center;
  z-index: 1;
  margin-left: 0px !important;
}

.bottom {
  background-color: #d7d4d2;
  width: 94%;
  bottom: 50px;
  position: absolute;
  height: auto;
  z-index: 1;
  margin-left: 0px !important;
  padding: 20px 0 10px 0;
}

.month {
  background-color: #d7d4d2;
  width: 94%;
  bottom: 25px;
  position: absolute;
  height: 40px;
  z-index: 1;
  margin-left: 0px !important;
  margin-bottom: -13px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.monthsText {
  color: #57473c;
  margin: 0px;
  display: flex !important;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.closeModal {
  box-sizing: border-box;
  position: absolute;
  z-index: 1000;
  right: 16px;
  top: 16px;
  border-radius: 50%;
}

.lineContainer {
  background: white;
  border-radius: 12px;
  min-height: 485px;
}
