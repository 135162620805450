@import "../../styles//variables.scss";

.Container {
  display: grid;
  grid-template-columns: 110px 0.7fr;
  gap: 17px;
  align-items: center;
}

.yearlyContainer {
  display: grid;
  gap: 10px;
  grid-template-columns: 80px 60px 100px;
}

.startDateContainer {
  display: grid;
  gap: 10px;
  grid-template-columns: 80px 1fr;
}

.mainContainer {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.repeatEveryContainer {
  display: grid;
  gap: 10px;
  grid-template-columns: 80px 1fr;
}

.radioContainer {
  display: flex;
  gap: 10px;
}

.radioGroup {
  display: grid;
  grid-template-columns: 80px 120px;
  gap: 10px;
}

.radioContainerSecond {
  display: flex;
  gap: 10px;
  margin-top: 16px;
}

.radioGroupSecond {
  display: grid;
  grid-template-columns: 110px 120px 20px 120px;
  gap: 10px;
  margin-left: 3px;
}
