@import "../../../styles/variables.scss";

.mainContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;

  & .innerContainer {
    width: 440px;
    display: flex;
    flex-direction: column;

    & .heading {
      margin-top: 229px;
      color: theme-color(mainWhite);
      font-size: 30px;
      margin-bottom: 25px;
    }

    & .imageSrc {
      position: absolute;
      left: 91%;
      height: 49px;
      width: 40px;
      border: 1px solid theme-color(secondaryWhite);
      border-left: none;
      border-radius: 0px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      background-color: theme-color(background-color-default);
      margin-right: 12px;
      &:hover {
        background: theme-color(primary);
      }
    }

    & .extrabuttons {
      margin-top: 20px;

      & button {
        cursor: pointer;
        background-color: transparent;
        padding: 0;
        outline: none;
        border: none;
        color: theme-color(mainWhite);
      }

      & .rememberMe {
        display: flex;
        cursor: pointer;
        background-color: transparent;
        align-items: center;
        padding: 0;
        outline: none;
        border: none;
        color: theme-color(mainWhite);

        & img {
          margin-right: 8px;
        }
      }
    }

    & .btnSubmit {
      width: 100%;
      margin-top: 60px;
      margin-bottom: 20px;
    }
  }
  & .footer {
    display: flex;
    justify-content: center;
    color: theme-color(secondaryWhite);
    margin-bottom: 30px;
    & img {
      margin-right: 14.6px;
      width: 180px;
    }
  }
}
