.tableAvatar {
  margin-right: 24px;
  position: relative;
}

.imgActual {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.popUpAvatar {
  position: relative;
}
