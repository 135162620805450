@import "../../styles/variables.scss";

.card {
  border: 2px solid$color-sec_aqua;
  box-shadow: 0px 11px 33.1px -10px rgba(173, 145, 111, 0.3);
  height: "100%";
}

.closeBtn {
  color: $color-white;
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: $color-dmv-background;
}

.image {
  width: 100%;
  height: 25px;
  object-fit: contain;
  margin-bottom: 16px;
}
