@import "../../styles/variables.scss";

.imageWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.imageContainer{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out, z-index 0.5s step-end;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;

  &.top {
    z-index: 2;
    transform: translateY(0) scale(1);
  }

  &.bottom {
    z-index: 1;
    transform: translateY(100%) scale(0.8);
  }

  &.loading {
    background: $color-loading;
  }
}

.actualImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}


