@import "../../../../styles/variables.scss";

.category {
  display: inline-flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #675950;
  background: #1e1a19;
  backdrop-filter: blur(2px);
}

.image {
  width: 353px;
  height: 150px;
  border-radius: 4px;
  object-fit: cover;
}

@media screen and (max-width: 1023px) {
  .image {
    width: 220px;
    height: 120px;
    border-radius: 4px;
    object-fit: cover;
  }
}

.cancelButton {
  padding: 13px 36px !important;
  margin-right: 16px !important;
  min-width: 145px !important;
}

.saveButton {
  background-color: theme-color(secondary) !important;
  padding: 13px 36px !important;
  min-width: 145px !important;
}

.emptyImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 353px;
  height: 150px;
  border: 1px solid;
  border-radius: 4px;
}

.uploadButton {
  padding: 10px 20px;
  min-width: 145px;
  border-radius: 6px;
  border: 2px solid #ad916f;
}
