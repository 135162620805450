@import "../../../../styles/variables.scss";

.arrowMenu {
  position: relative;
  background-color: theme-color(primary);
  color: white;
  padding: 32px;
  border-radius: 12px;
  margin-left: 24px !important;
  min-height: 80vh;
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
}

.arrowMenuContent {
  position: relative;
}

.itemLabel {
  border-bottom: 1px solid theme-color(colorLightBackgroundDefault);
  padding-block: 20px;
}

.arrow {
  position: absolute;
  top: 30px;
  left: -20px;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-right: 30px solid theme-color(primary);
}

.addNewField {
  color: theme-color(secondary);
  text-decoration-line: underline;
  cursor: pointer;
}

.category_grid {
  border-radius: 4px;
  padding: 10px;
  border: 1px solid;
  margin-bottom: 10px !important;
  cursor: pointer;
}

.action_btn {
  max-height: 48px;
}
.newField {
  height: 62px;
}
