@import "../../styles/variables.scss";

.box {
  position: relative;

  .input {
    padding: 10px 0px 10px 9px;
    width: 54px;
    height: 52px;
    border-radius: 6px;
    border: 2px solid $color-border;
    box-sizing: border-box;
    font-family: Inter-Regular;
    color: $color-white;
    outline: none;
    transition: 0.3s ease;

    &.expanded,
    &:hover {
      width: 300px;
      border-radius: 4px;
      cursor: pointer;
    }
    ::-webkit-search-cancel-button {
      appearance: none;
      background-image: url(https://icones.pro/wp-content/uploads/2022/05/icone-fermer-et-x-rouge-1.png);
      background-size: cover;
      height: 14px;
      width: 14px;
    }
  }

  .MuiInputAdornment-root {
    color: $color-secondary;
  }
  .css-aeeyth-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10.5px 6px;
  }
}
