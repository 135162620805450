@import "../../styles/variables.scss";

.image-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease-in-out, z-index 0.5s step-end;
  border-radius: 12px;

  &.top {
    z-index: 2;
    transform: translateY(0) scale(1);
  }

  &.bottom {
    z-index: 1;
    transform: translateY(100%) scale(0.8);
  }

  &.loading {
    background: $color-loading;
  }
}

.loading-image,
.actual-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 12px;
}

.loading-image {
  border-radius: 12px;
}
