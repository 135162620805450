@import "../../../../styles/variables.scss";

.title {
  background: $color-dmv-secondary-background;
  padding: 36px 24px;
  border-radius: 12px;
  margin-top: 40px;
}

.container {
  background: $color-dmv-secondary-background;
  padding: 36px 24px;
  border-radius: 12px;
}

.card {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
}

.itemContainer {
  border: 1px solid $color-white-secondary;
  border-radius: 6px;
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 3fr 20px;
  gap: 8px;
  height: 100%;
}

.itemsContainer {
  border: 1px solid $color-white-secondary;
  border-radius: 6px;
  padding: 15px;
  display: grid;
  gap: 8px;
  grid-template-columns: 3fr 20px;
  height: 100%;
}

.selectedItemsImage {
  height: 70px;
  width: 70px;
  border-radius: 12px;
  object-fit: contain;
}

.selectedItemsText {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  overflow-wrap: break-word;
}

.closeIcon {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: #0b0b0b;
  padding: 4px;
}

.image {
  height: 180px;
  width: 180px;
  border-radius: 12px;
  object-fit: fill;
}

.radio {
  position: absolute !important;
  top: 5px;
  right: 5px;
  width: 22px;
}

.Container {
  border: 1px solid $color-white-secondary;
  padding: 28px;
  position: relative;
  border-radius: 12px;
}
.imageContainer {
  width: 100%;
  overflow: hidden;
  aspect-ratio: 1 / 1;
}

.profileImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.selectVipText {
  color: $color-white-secondary;
  text-decoration: underline;
  text-align: left;
  font-style: italic;
}

.radioButton {
  display: flex;
  cursor: pointer;
  background-color: transparent;
  align-items: center;
  padding: 0;
  outline: none;
  border: none;
  color: theme-color(mainWhite);
}
