@import "../../../../styles/variables.scss";

.exportDialogBox {
  background-color: $color-dmv-background;
  border-radius: 12px;
  border: none;
  width: 650px;
  padding: 24px;
  box-sizing: border-box;
}

@media only screen and (max-width: 1205px) {
  .exportDialogBox {
    box-sizing: border-box;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.closeButton {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: theme-color(background-color);
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabContainer {
  margin-bottom: 25px;
}

.tableHead {
  border-bottom: none !important;
}
.totalCell {
  position: sticky !important;
  left: 0;
  z-index: 20 !important;
  min-width: 250px;
}
.countCell {
  position: sticky !important;
  left: 0;
  z-index: 20 !important;
  min-width: 63px;
}

.nameCell {
  position: sticky !important;
  z-index: 20 !important;
  left: 63px;
  min-width: 250px;
}

.headerText {
  opacity: 0.6;
}

.quizCell {
  min-width: 165px;
}

.evenCell {
  background-color: #0c121e !important;
}

.expandableRow {
  padding: 0px !important;
  border: none !important;
}

.noDataContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.quizGamesPlayed {
  border-bottom: 2px solid #ffffff80 !important;
}

.gamesPlayed {
  border-bottom: none !important;
}
