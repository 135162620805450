@import "../../styles/variables.scss";

.container {
  border-bottom: 2px solid $color-border;
  padding-top: 4px;
  padding-left: 4px;
}
.text {
  color: $color-sec_aqua;
}
.filterContainer {
  margin-right: 4px;
}
