@import "../../../../../../styles/variables.scss";

.container {
  width: 100%;
  overflow: hidden;
  margin-top: -2px;
}

.header {
  opacity: 0.6;
  position: relative;
  text-align: center;
}

.serialNo {
  border-width: 2px;
  border-style: solid;
  border-radius: 6px;
  padding: 12px 10px;
  width: fit-content;
  border-color: theme-color(mainWhite);
}

.questionCtr {
  border-radius: 12px;
  padding: 24px;
  padding-left: 27px;
  display: flex;
  justify-content: space-between;
}

.expandableRow {
  padding: 0px !important;
  border: none !important;
}

.question {
  display: flex;
  flex-direction: column;
}

.questionHeader {
  text-transform: uppercase;
}

.questionTitle {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  height: 45px;
}

.option {
  margin-left: 52px;
  display: flex;
  align-items: center;
  margin-top: 12px;

  & .optionNumber {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
  }
}

.optionCorrect {
  & .optionNumber {
    background-color: theme-color(mainGreen);
    color: theme-color(background-color);
  }
  & .optionName {
    color: theme-color(mainGreen);
  }
}

.avatar {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  margin: 16px 0px 10px;
}

.suggestedBy {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.borderBottomNone {
  border-bottom: none !important;
}

.questionBankCtr {
  border-radius: 12px;
  padding: 24px;
  padding-left: 27px;
  background-color: theme-color(background-color-default);
  display: flex;
}

.indexBorder {
  width: 29.05px;
  height: 44px;
  border-radius: 6px;
  border: 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question_bank_option {
  display: grid;
  grid-template-columns: 20px 1fr;
  column-gap: 10px;
  align-items: center;
  margin-top: 12px;
  padding: 4px;

  & .optionNumber {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
  }
}

.questiondiv {
  display: flex;
  justify-content: space-between;
}

.countBorder {
  padding: 0px 10px;
  height: 44px;
  border-radius: 6px;
  border: 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
}
