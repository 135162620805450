@import "../../styles/variables.scss";

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: $color-loading;
  border-radius: 12px;
}

.loadingImage {
  width: 100%;
  height: 100%;
  background: $color-loading;
  border-radius: 12px;
}
