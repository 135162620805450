@import "../../../styles/variables.scss";

.mainContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;

  & .innerContainer {
    width: 500px;
    display: flex;
    flex-direction: column;

    & .heading {
      color: theme-color(mainWhite);
      font-size: 30px;
    }
  }
}

.searchContainer {
  border: 1px solid;
}

.restaurantContainer {
  background-color: theme-color(background-color-default);
  border-radius: "12px";
  justify-content: "center";
  align-items: "center";
  display: "flex";
  flex-direction: "column";
  padding: "8px 0px";
}

.footer {
  display: flex;
  justify-content: center;
  color: theme-color(secondaryWhite);
  margin-bottom: 30px;
  & img {
    margin-right: 14.6px;
    width: 180px;
  }
}

.logout_grid {
  border: 2px solid theme-color(secondary);
  padding: 12px 14px;
  border-radius: 6px;
}

.liteBtn {
  height: 44px;
  width: 90px;
}
.liteContainer {
  display: flex;
  justify-content: flex-end;
}
