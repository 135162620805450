@import "../../../../styles/variables.scss";

.rank {
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableAvatar {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  &__img {
    & > img {
      border-radius: 50%;
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
  }
}

.schedule {
  color: theme-color(mainOrange);
}

.failure {
  color: theme-color(secRed);
}

.deliver {
  color: theme-color(mainGreen);
}


.icon {
  background-color: #a47f511a;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.title {
  color: #a47f51 !important;
  text-decoration: underline;
  text-underline-offset: 2px;
  margin-left: 5px !important;
}

.date {
  color: #c4c4c4;
}

.moreStreaks {
  background-color: $color-sec_aqua;
  padding: 6px;
  border-radius: 50%;
  margin-left: 5px;
  cursor: pointer;
}
